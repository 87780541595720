import { Injectable } from '@angular/core';
import { SettingClient } from '@shared/data-access/common';
import { firstValueFrom } from 'rxjs';
import { Monitor } from './webapi';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  static fieldWorkClosedSetting = 'FieldWorkClosed';
  static copyMeasurementYear = 'CopyMeasurementYear';
  static fieldWorkClosedSettingText = 'FieldWorkClosedText';
  static isInvitationClosedSettingText = 'IsInvitationClosedTooltip';
  static isInvitationClosedSetting = 'IsInvitationClosed';
  // RegExps for names of Groups in PO and VO: i.e. start with 1 letter followed by character
  static regexpGroupPO = '^(([1-8]{1}[A-Za-z\\s]{0,20})|onderbouw|middenbouw|bovenbouw)$';
  static regexpGroupVO = '^([1-6]{1}[A-Za-z0-9\\s]{0,20})$';
  static regexpGroupBookStart = '^([A-Za-z0-9]{1,1})([A-Za-z0-9\\s]{0,20})$';

  public measurementYear = 0;
  public fieldWorkIsClosed = false;

  private fieldWorkIsClosedIsRetrieved = false;
  private isInvitationClosed = false;
  private isInvitationClosedIsRetrieved = false;
  private isMeasurementYearRetrieved = false;

  constructor(private settingsClient: SettingClient) {}

  getRegExpGroupName(monitor: Monitor.Po | Monitor.BookStart | Monitor.Vmbo) {
    switch (monitor) {
      case Monitor.Po:
        return SettingsService.regexpGroupPO;
      case Monitor.Vmbo:
        return SettingsService.regexpGroupVO;
      default:
        return SettingsService.regexpGroupBookStart;
    }
  }

  async resolve() {
    if (!this.fieldWorkIsClosedIsRetrieved) {
      this.fieldWorkIsClosed = await this.isFieldWorkIsClosed();
    }
    if (!this.isInvitationClosedIsRetrieved) {
      this.isInvitationClosed = await this.isInvitationIsClosed();
    }
    if (!this.isMeasurementYearRetrieved) {
      this.measurementYear = await this.getMeasurementYear();
    }
  }

  async isFieldWorkIsClosed(): Promise<boolean> {
    if (!this.fieldWorkIsClosedIsRetrieved) {
      this.fieldWorkIsClosed = (await firstValueFrom(this.settingsClient.getSetting(SettingsService.fieldWorkClosedSetting))) === 'true';
      this.fieldWorkIsClosedIsRetrieved = true;
    }
    return this.fieldWorkIsClosed;
  }

  async getMeasurementYear(): Promise<number> {
    if (!this.isMeasurementYearRetrieved) {
      this.measurementYear = parseInt((await firstValueFrom(this.settingsClient.getSetting(SettingsService.copyMeasurementYear))) + '');
      this.isMeasurementYearRetrieved = true;
    }
    return this.measurementYear;
  }

  async isInvitationIsClosed(): Promise<boolean> {
    if (!this.isInvitationClosedIsRetrieved) {
      this.isInvitationClosed = (await firstValueFrom(this.settingsClient.getSetting(SettingsService.isInvitationClosedSetting))) === 'true';
      this.isInvitationClosedIsRetrieved = true;
    }
    return this.isInvitationClosed;
  }

  async setFieldWorkClosed(closed: boolean) {
    await firstValueFrom(this.settingsClient.setSetting(SettingsService.fieldWorkClosedSetting, closed + ''));
    this.fieldWorkIsClosed = closed;
    this.fieldWorkIsClosedIsRetrieved = false;
  }

  async setCopyMeasurementYear(year: number) {
    await firstValueFrom(this.settingsClient.setSetting(SettingsService.copyMeasurementYear, year + ''));
    this.isMeasurementYearRetrieved = false;
  }

  async setIsInvitationClosed(closed: boolean) {
    await firstValueFrom(this.settingsClient.setSetting(SettingsService.isInvitationClosedSetting, closed + ''));
    this.isInvitationClosed = closed;
    this.isInvitationClosedIsRetrieved = false;
  }

  async setSettingText(key: string, text: string) {
    await firstValueFrom(this.settingsClient.setSetting(key, text));
  }

  async getSettingText(key: string): Promise<string | null> {
    return await firstValueFrom(this.settingsClient.getSetting(key));
  }
}
